<template>
  <div class="main">
    <Head title="我的考试"></Head>
    <div class="el">
      <div class="el-item">
        <div class="el2">
          <el-table
              :data="tableData"
              border
              style="width: 100%"
              :header-cell-style="{ background: '#f5f5f5' }"
          >
            <!-- <el-table-column label="考试类型" width="100">
              <template slot-scope="scope">
                <p v-if="scope.row.type === 0">培训课</p>
                <p v-if="scope.row.type === 1">公开课</p>
                <p v-if="scope.row.type === 2">直播课</p>
              </template>
            </el-table-column> -->
            <el-table-column label="考试课程" width="240">
              <template slot-scope="scope">
                <img :src="scope.row.courseLogo" alt="" class="Course-item-img"/>
              </template>
            </el-table-column>
            <el-table-column prop="courseName" label="考试名称" />
            <el-table-column prop="examTime" label="考试时间" width="200" />
            <el-table-column prop="score" label="考试成绩" width="160">
              <template slot-scope="scope">
                {{convertScore(scope.row)}}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <div class="btn-small">
                  <el-button
                      type="primary"
                      size="mini"
                      @click="goDetail(scope.row.courseId)"
                  >前往课程
                  </el-button>
                  <!-- <el-button
                    type="primary"
                    size="mini"
                    @click="handleRenewal(scope.row.examId)"
                    >继续考试
                  </el-button> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <Pagination
              :total="search.total"
              :page.sync="search.page"
              :limit.sync="search.size"
              @pagination="getExamList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { getExamList } from "@/api/myCenter";
import Head from './Head'
export default {
  components: {
    Pagination,
    Head
  },
  data() {
    return {
      search: {
        page: 1,
        size: 10,
        total: 0, //分页
      },
      total: 0, //分页
      tableData: [
      ],
    };
  },
  mounted() {
    this.getExamList();
  },
  methods: {
    // 获取数据
    getExamList() {
      getExamList(this.search).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.search.total = res.data.data.total;
          this.tableData = res.data.data.rows
        }
      });
    },
    convertScore(row) {
      if (row.examStatus === 0) {
        return "未考试";
      } else if (row.examStatus === 1) {
        return "未完成";
      } else if (row.examStatus === 2) {
        return "待批改";
      } else if (row.examStatus === 3) {
        if(row.isPass === 1){
          return row.score + "分(通过)";
        }else if(row.isPass === 0){
          return row.score + "分(未通过)";
        }
      }
    },
    //查看考试结果
    goDetail(courseId) {
      this.$router.push({
        path: `/classdetail?id=${courseId}`
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  background: #fff;
  span {
    display: flex;
  }
  .el {
    border-top: 1px solid #f5f5f5;
    margin-top: 10px;
    .el-item {
      margin-top: 20px;
    }
  }
  .pagination {
    margin-top: 30px;
  }
  .Course-item-img {
    width: 227px;
  }
  .el{
    padding: 10px 20px;
    margin-top: 0px !important;
    // min-height: 62vh;
  }
  .el2{

  }
}
</style>